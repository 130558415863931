import $ from "jquery";
import "../css/reset.css"
import "../css/tailwind.css"
import "../scss/screen.scss"

var Main = Main || {};

Main = (function () {
  var fn = {};
  var settings = {};

  /**
   * Initialisation function. Loads in any settings passed to the script
   * @param  {Object} obj
   * @return none
   */
  fn.init = function (obj) {
    Main.settings = obj;
    Main.overlayController();
    Main.formSender();
    Main.mobileNavigationController();
    Main.mobileCallController();
  };


  fn.overlayController = function () {
    // If this is not a mobile device (Table, or otherwise)
    
    // Opening overlays
    $(document).on("click", "#booking-button", function (event) {
      event.preventDefault();
      var overlayContent = $(this).data("overlay");
      var overlayVehicleType = $(this).data("vehicleType");
      $("html").addClass("locked-scrolling");
      $(".overlay").addClass("active");
      $(".overlay__content--" + overlayContent).addClass(
        "overlay__content--active"
      );
      $(
        '.overlay__content--active select[name=vehicleType] option[data-id="' +
          overlayVehicleType +
          '"]'
      ).attr("selected", "selected");
    });

    // Closing overlays
    var closingLinks =
      ".overlay.active .button--close, .overlay.active .overlay__background";
    $(document).on("click", closingLinks, function (event) {
      event.preventDefault();
      closeOverlay();
    });

    $(document).keyup(function (event) {
      if (event.keyCode === 27) {
        closeOverlay();
      }
    });

    function closeOverlay() {
      $("html").removeClass("locked-scrolling");
      $(".overlay.active").removeClass("active");
      $(".overlay .overlay__content").removeClass("overlay__content--active");
    }
  };

  fn.formSender = function () {
    $("form.ajax-form").on({
      submit: function () {
        var $self = $(this);
        $(".alert.alert--success", $self).remove();
        $(".alert.alert--error", $self).remove();
        $(".has-error", $self).removeClass("has-error");
        $("button[type=submit]", $self)
          .prop("disabled", true)
          .html("Sending...");

        $.ajax({
          url: $("input[name=action]", $(this)).val(),
          type: "post",
          dataType: "json",
          data: $(this).serialize(),
          success: function (response) {
            if (response.success && response.finished) {
              var $successMessage = $("<div>", {
                class: "alert alert--success",
              }).append(
                "<p>Thank you for your submission. We'll be in touch soon.</p>"
              );
              $self.before($successMessage);
              $self.hide();
            } else if (response.errors) {
              console.log(response.errors);
              for (var key in response.errors) {
                if (!response.errors.hasOwnProperty(key)) {
                  continue;
                }
                // var messages = response.errors[key];
                // var $errors  = $("<ul>", {"class": "errors help-block"});
                // for (var i = 0; i < messages.length; i++) {
                //     var $li = $("<li>");
                //     $li.html(messages[i]);
                //     $errors.append($li);
                // }
                var $input = $(
                  "*[name=" + key + "], *[name='" + key + "[]']"
                ).addClass("has-error");
              }

              var $errorMessage = $("<div>", {
                class: "alert alert--error",
              }).append(
                "<p>Required fields have been missed. Please check and try again.</p>"
              );
              $self.prepend($errorMessage);
              $self.append($errorMessage);
              $("button[type=submit]", $self)
                .prop("disabled", false)
                .html("Submit");
            }
          },
        });

        return false;
      },
    });
  };

  fn.mobileNavigationController = function () {
    $(document).on(
      "click",
      ".nav-toggle",
      function (event) {
        event.preventDefault();
        $("body").toggleClass("mobile-nav--open mobile-nav--closed");
      }
    );
  };

  fn.mobileCallController = function () {
    $(".header-links__link--call-toggle").click(function (event) {
      event.stopPropagation();
      $(".header__call-panel").toggleClass("header__call-panel--open");
    });

    $("body").click(function () {
      if ($(".header__call-panel").hasClass("header__call-panel--open")) {
        console.log("click!!! remove...");
        $(".header__call-panel").removeClass("header__call-panel--open");
      }
    });

    $(".header__call-panel").click(function (e) {
      e.stopPropagation();
    });
  };

  return fn;
})();

Main.init();